import React from "react";
import { ImagePart } from "./image";
import { StyledPostCard } from "./post-card.styles";
import { LinkPart } from "./link";
import { InViewElement } from "./in-view-element";
import { PostCardFragmentFragment } from "../../gatsby-graphql";
import { graphql } from "gatsby";

export interface IPostCardProps extends PostCardFragmentFragment {
  isOdd?: boolean;
}

const PostCard: React.FC<IPostCardProps> = props => {

  return (
    <StyledPostCard>
      <InViewElement>
        <LinkPart to={props.slug ? `/post/${props.slug}` : '/blog'} >
          <div className="post-card__content">
            <div className="post-card__overlay" />
            {props.featuredImage &&
              <ImagePart src={props.featuredImage.node?.src?.childImageSharp?.gatsbyImageData} ratio="square" />
            }
            <h3 className="post-card__title">{props.title}</h3>
          </div>
        </LinkPart>
      </InViewElement>
    </StyledPostCard>
  );
}

export default PostCard;

export const PostCardFragment = graphql`
    fragment PostCardFragment on WpPost {
        title
        slug
        author {
          node {
            name
          }
        }
        date
        featuredImage {
          node {
            ...ImageFragment
          }
        }
    }
`;
