import React from "react";
import SEO from "../components/seo";
import PostCard from "../parts/post-card";
import { StyledBlogIndexPage } from "./blog-index-page.styles";
import { usePosts } from "../hooks/posts.hook";

const BlogIndexPage:React.FC = () => {
  const posts = usePosts();

  return (
    <StyledBlogIndexPage className="template template--blog-index">
      <SEO title="Blog" keywords={[`gatsby`, `application`, `react`]} />
      <h1 className="blog__title">Blog</h1>
      <div className="blog__grid">
        {posts?.nodes && posts.nodes.map((post, index: number) => {
            return !!post && <PostCard {...post} key={index} />;
        })}
      </div>
    </StyledBlogIndexPage>
  )
};

export default BlogIndexPage;
