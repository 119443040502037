import styled from 'styled-components';
import { media } from '../../global/media.styles';

export const StyledLatestPosts = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    @media ${media.tablet} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${media.tabletLandscape} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;
