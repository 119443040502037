import styled from 'styled-components';
import { colors, layer } from '../global/theme.styles';
import { h2Styles } from '../global/typography.styles';
import { animation, baseEasingFunction } from '../global/animation.styles';
import { media } from '../global/media.styles';
import { StyledImage } from './image.styles';
import { StyledLink } from './link.styles';
import { StyledLatestPosts } from '../gutenberg/blocks/latest-posts.styles';
import { StyledInView } from './in-view-element.styles';

export const StyledPostCard = styled.div`
    display: inline-block;
    position: relative;
    overflow: hidden;

    &:nth-child(odd) {

        .post-card__overlay {
            background-color: ${colors.brandBlue};
        }
    }

    &:nth-child(even) {

        .post-card__overlay {
            background-color: ${colors.brandOrange};
        }
    }

    @media ${media.mobile} {
        ${StyledLatestPosts} & {
            display: none;

            &:nth-child(-n+3) {
                display: inline-block;
            }

            &:last-child {
                display: inline-block;

                .post-card__overlay {
                    background-color: ${colors.brandOrange};
                }
            }
        }
    }

    @media ${media.tablet} {
        display: inline-block;

        ${StyledLatestPosts} & {
            display: inline-block;
        }

        &:nth-child(4n-2),
        &:nth-child(4n-1) {

            .post-card__overlay {
                background-color: ${colors.brandOrange};
            }
        }

        &:nth-child(4n),
        &:nth-child(4n+1) {
            .post-card__overlay {
                background-color: ${colors.brandBlue};
            }   
        }
    }

    @media ${media.tabletLandscape} {

        &:nth-child(odd) {

            .post-card__overlay {
                background-color: ${colors.brandBlue};
            }
        }

        &:nth-child(even) {

            .post-card__overlay {
                background-color: ${colors.brandOrange};
            }
        }
    }

    &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    & ${StyledInView} {
        height: 100%;
        width: 100%;
    }

    & ${StyledLink} {
        position: absolute;
        height: 100%;
        width: 100%;

        a {
            color: ${colors.white};
            text-decoration: none;
        }

        &:hover {
            & .post-card__overlay {
                opacity: 0.4;
            }

            & ${StyledImage} {
                transform: scale(1.1);
                transform-origin: center;
                filter: grayscale(0) contrast(1);
            }

            & .post-card__title {
                transform: translate3d(0, -15px, 0);
            }
        }
    }

    & .post-card {

        &__content {
        }

        &__title {
            ${h2Styles}
            position: absolute;
            bottom: 0;
            padding: 25px;
            margin: 0;
            z-index: ${layer.postCardTitle};
            transition: transform ${animation.hoverSecondary};
        }

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0.8;
            transition: opacity ${animation.hover};
            z-index: ${layer.postCardOverlay};
        }
    }

    & ${StyledImage} {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        transform: scale(1);
        filter: grayscale(0.8) contrast(1.2);
        transition: transform ${animation.hoverImageZoom}, filter ${animation.hoverImageZoom};

        .gatsby-image-wrapper {
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            max-width: none;
            object-fit: cover;

            &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
        }
    }
`;
