import styled from 'styled-components';
import { TransitionStyles } from '../global/animation.styles';
import { fadeFromSideAndInView, fadeInView, fadeUpAndInView } from '../global/placeholders';

export interface IStyledInViewProps {
    transitionStyle?: TransitionStyles;
}

const handleTransitionType = (style: string | undefined) => {
    switch (style) {
        case "fade-in":
            return fadeInView;
        case "fade-up":
            return fadeUpAndInView;
        case "fade-side":
            return fadeFromSideAndInView;
        case "none":
            return;
        default:
            return fadeInView;
    }
};

export const StyledInView = styled.span<IStyledInViewProps>`
    display: inherit;
    position: initial;
    ${p => handleTransitionType(p.transitionStyle)}
`;
