import styled from 'styled-components';
import { media } from '../global/media.styles';
import { blockSpacing, spacing } from '../global/spacing.styles';
import { fancyH1Styles } from '../global/typography.styles';

export const StyledBlogIndexPage = styled.div`
    padding-top: ${spacing.pageTopPadding}px;

    .blog {
        &__title {
            ${fancyH1Styles}
            ${blockSpacing}
        }

        &__grid {
            display: grid;
            grid-template-columns: 1fr;

            @media ${media.tablet} {
                grid-template-columns: 1fr 1fr;
            }

            @media ${media.tabletLandscape} {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
`;
