import { useStaticQuery, graphql } from "gatsby";
import { PostsQueryQuery } from "../../gatsby-graphql";

export const usePosts = () => {
  const data = useStaticQuery<PostsQueryQuery>(graphql`
    query PostsQuery {
        allWpPost {
          nodes {
              ...PostCardFragment
          }
        }
  }`
  )

  return data.allWpPost
}